<template>
  <v-app>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar Perjanjian <b>{{ currentDoctor.name }}</b>
      </div>
    </b-alert>

    <div class="row">

      <!-- Main Content -->
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Nav -->
            <b-tabs
              class="mt-n3"
              content-class="mt-3"
            >
              <b-tab
                title="Kalender"
                active
              >
                <!-- Calendar -->
                <Calendar
                  :isMyAppointment="true"
                  :currentDoctor="currentDoctor"
                />
              </b-tab>
              <b-tab title="Perjanjian">
                <!-- Appointment List -->
                <Table
                  :items="items"
                  :itemExcel="itemExcel"
                  :itemPopUp="itemPopUp"
                  :fields="fields"
                  :fieldPopUp="fieldPopUp"
                  :isMyAppointment="true"
                  :perPage="perPage"
                  :currentPage="currentPage"
                  :totalRows="totalRows"
                  :tabFiled="tabFiled"
                  @filter="generalFilter"
                  @filterByPatient="generalFilter"
                  @appointmentOnStatusChange="pagination"
                  @pageOnClick="pageOnClick"
                />
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>

    </div>

  </v-app>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/appointments/Table.vue'
import Calendar from '@/component/doctors/Calendar.vue'
import module from '@/core/modules/CrudModule.js'
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table,
    Calendar
  },

  data() {
    return {
      // filter
      filter: {
        appointment_status_id: '',
        start_date: '',
        end_date: '',
        patient_name: '',
      },
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "action_type_name",
          label: "Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldPopUp: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "action_type_name",
          label: "Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      itemExcel:[],
      itemPopUp: [],
      // Other
      tabFiled: 0,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&doctor_id=${this.currentDoctor.id}&patient_name=${this.filter.patient_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate('appointments', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination

      let responseExcel = await module.get('appointment-excel', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let responsePopUp = await module.get('appointment-no-paginations' ,`?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
    
      this.itemExcel = responseExcel
      this.itemPopUp = responsePopUp

      this.items = response.data
      this.totalRows = pagination.total
    },

    async generalFilter(sended) {
      this.filter.appointment_status_id = sended.appointment_status_id
      this.filter.patient_name = sended.patient_name
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.pagination()
    },

  },

  computed: {
    ...mapGetters({
      currentDoctor: 'currentUserPersonalInfo'
    })
  },

  mounted() {
    console.log(this.currentDoctor)
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Perjanjian Saya" }
    ])
    // Table List
    this.pagination()
  }

}
</script>

<style>
</style>
<template>
  <div>
    <div id="print" class="d-none">
      <!-- print preview -->
      <div id="printPreview">
        <div class="row justify-content-center align-items-center h-100 w-100">
          <div
            class="col-lg-5 col-12 sticker-container"
            v-for="loop in parseInt(count)"
            :key="loop"
          >
            <div class="card border-secondary" style="border-radius: 0px">
              <div
                class="card-body d-flex align-items-center justify-content-center mx-2"
              >
                <div>
                  <h5 class="mb-1">
                    <strong>{{
                      `${data.patient_name.toUpperCase()} (${showPatientGender(
                        data.patient_gender
                      )})`
                    }}</strong>
                  </h5>
                  <div>
                    <strong>{{
                      `${data.patient_age} tahun / ${new Date(
                        data.patient_birth_date
                      )
                        .toLocaleString("id-ID", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                        .replaceAll("/", "-")}`
                    }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- print layout -->
      <div id="printLayout" class="d-none">
        <div
          class="d-flex align-items-start justify-content-center"
          v-for="loop in parseInt(count / 2)"
          :key="loop"
        >
          <div class="sticker-container-left" style="border-radius: 0px">
            <div
              class="d-flex inner-sticker-container-left justify-content-center"
            >
              <div>
                <div class="patient-name">
                  <strong>{{
                    `${data.patient_name.toUpperCase()} (${showPatientGender(
                      data.patient_gender
                    )})`
                  }}</strong>
                </div>
                <div class="patient-info">
                  <strong>{{
                    `${data.patient_age} tahun / ${new Date(
                      data.patient_birth_date
                    )
                      .toLocaleString("id-ID", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replaceAll("/", "-")}`
                  }}</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="sticker-container-right" style="border-radius: 0px">
            <div
              class="d-flex inner-sticker-container-right justify-content-center"
            >
              <div>
                <div class="patient-name">
                  <strong>{{
                    `${data.patient_name.toUpperCase()} (${showPatientGender(
                      data.patient_gender
                    )})`
                  }}</strong>
                </div>
                <div class="patient-info">
                  <strong>{{
                    `${data.patient_age} tahun / ${new Date(
                      data.patient_birth_date
                    )
                      .toLocaleString("id-ID", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replaceAll("/", "-")}`
                  }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },

  data() {
    return {
      count: 2,
    };
  },

  methods: {
    showPatientGender(gender) {
      if (gender.toLowerCase() == "male") return "L";
      else if (gender.toLowerCase() == "female") return "P";
      else if (gender.toLowerCase() == "perempuan") return "P";
      else if (gender.toLowerCase() == "laki-laki") return "L";
    },
  },
};
</script>

<style>
</style>